if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.shooz = {
  init__shooz: function() {
    $('.shooz-brands__slider').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      speed: 1000,
      nextArrow:
        '<button type="button" class="slick-next"><svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8186 10.4826L2.24158 0.835107C1.68373 0.207628 0.646018 0.602215 0.646018 1.44182C0.646018 1.65825 0.722891 1.86764 0.862928 2.03265L8.0633 10.5173C8.69676 11.2638 8.69676 12.3591 8.0633 13.1055L0.862929 21.5902C0.722891 21.7552 0.646018 21.9646 0.646018 22.181C0.646018 23.0206 1.68373 23.4152 2.24158 22.7878L10.8186 13.1403C11.4923 12.3825 11.4923 11.2404 10.8186 10.4826Z" fill="#333333"/></svg></button>',
      prevArrow:
        '<button type="button" class="slick-prev"><svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.18141 10.4826L9.75842 0.835107C10.3163 0.207628 11.354 0.602215 11.354 1.44182C11.354 1.65825 11.2771 1.86764 11.1371 2.03265L3.9367 10.5173C3.30324 11.2638 3.30324 12.3591 3.9367 13.1055L11.1371 21.5902C11.2771 21.7552 11.354 21.9646 11.354 22.181C11.354 23.0206 10.3163 23.4152 9.75842 22.7878L1.18141 13.1403C0.507682 12.3825 0.507682 11.2404 1.18141 10.4826Z" fill="#333333"/></svg></button>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
      ],
    });
    Steenify.shooz.init__stores();
  },
  init__stores: function() {
    var ele = $('#stores__content').first(),
      url =
        ele.data('map-url') +
        '?key=' +
        ele.data('map-key') +
        '&libraries=places';

    if (
      'google' in window &&
      (typeof google === 'undefined' ? 'undefined' : _typeof(google)) ===
        'object' &&
      _typeof(google.maps) === 'object'
    ) {
      $('#stores__content').mapview();
    } else {
      $.getScript(url, function() {
        $('#stores__content').mapview();
      });
    }
  },
};
