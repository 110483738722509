if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.home = {
  init__home: function() {
    Steenify.home.init__banner__home();
    Steenify.home.init__slider__logos();
  },
  init__banner__home: function() {
    // $('.banner-slider .paging__item').on('click', function() {
    //   const self = $(this),
    //     paging = self.closest('.paging');
    //   if (!self.hasClass('active')) {
    //     paging.find('.paging__item.active').removeClass('active');
    //     self.addClass('active');
    //   }
    // });

    $('.slider-for').on('init', function(event, slick) {
      const self = $(this);
      self.find('[data-slick-index="0"]').addClass('animated');
    });

    $('.slider-for').slick({
      asNavFor: '.slider-nav',
      speed: 1000,
      vertical: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            vertical: false,
          },
        },
      ],
    });

    $('.slider-for').on('beforeChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      const self = $(this);
      self
        .find('[data-slick-index="' + currentSlide + '"]')
        .removeClass('animated');
    });

    $('.slider-for').on('afterChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      const self = $(this);
      self
        .find('[data-slick-index="' + currentSlide + '"]')
        .addClass('animated');
    });

    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      asNavFor: '.slider-for',
      focusOnSelect: true,
      nextArrow:
        '<button type="button" class="slick-next"><svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1L7.38889 8L0.77778 0.999999" stroke="currentColor" stroke-width="2"/></svg></button>',
      prevArrow:
        '<button type="button" class="slick-prev"><svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L7.61111 2L14.2222 9" stroke="currentColor" stroke-width="2"/></svg></button>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            vertical: false,
          },
        },
      ],
    });
  },
  init__slider__logos: function() {
    if ($('.slider-logos').length) {
      $('.slider-logos').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        prevArrow:
          '<button type="button" class="slick-prev"><svg width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.1616 0.209961L10.4287 0.209961L2.26711 11.9989H0L8.1616 0.209961Z" fill="currentColor"/><path d="M2.26723 11.999L0.00012207 11.999L8.16172 23.788H10.4288L2.26723 11.999Z" fill="currentColor"/></svg></button>',
        nextArrow:
          '<button type="button" class="slick-next"><svg width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.26711 23.7881L0 23.7881L8.1616 11.9991L10.4287 11.9991L2.26711 23.7881Z" fill="currentColor"/><path d="M8.1616 11.999L10.4287 11.999L2.26711 0.210046L-1.03063e-06 0.210046L8.1616 11.999Z" fill="currentColor"/></svg></button>',
        responsive: [
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 364,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }
  },
};
