if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.geox = {
  init__geox: function() {
    $('.geox-slider').slick({
      prevArrow:
        '<button type="button" class="slick-prev"><svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.64293 0.428832L8.42864 0.428832L2.00007 9.71455L0.214357 9.71455L6.64293 0.428832Z" fill="#F6F5F5"/><path d="M2.00014 9.71454L0.214425 9.71454L6.643 19.0003L8.42871 19.0003L2.00014 9.71454Z" fill="#F6F5F5"/><line x1="22" y1="9.75024" x2="1" y2="9.75025" stroke="#F6F5F5" stroke-width="1.5"/></svg></button>',
      nextArrow:
        '<button type="button" class="slick-next"><svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.3571 18.5714L13.5714 18.5714L19.9999 9.2857L21.7856 9.2857L15.3571 18.5714Z" fill="#F6F5F5"/><path d="M19.9999 9.28571L21.7856 9.2857L15.357 -8.73918e-06L13.5713 -8.58307e-06L19.9999 9.28571Z" fill="#F6F5F5"/><line x1="-6.55671e-08" y1="9.25" x2="21" y2="9.25" stroke="#F6F5F5" stroke-width="1.5"/></svg></button>',
    });

    $('.geox-slider').on('beforeChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      $('.geox-thumbs-slider').slick('slickGoTo', nextSlide);
    });

    $('.geox-slider').on('afterChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      const self = $(this);
      const number = currentSlide + 1;
      const eleCurrentSlide = self
        .closest('.geox-info__slider')
        .find('[data-current-slide]');
      if (number < 10) {
        eleCurrentSlide.html('0' + number);
      } else {
        eleCurrentSlide.html(number);
      }
    });

    $('.geox-thumbs-slider').slick({
      fade: true,
      swipe: false,
      arrows: false,
    });

    // Steenify.geox.init__map__geox();
    Steenify.geox.init__feature_geox();
    Steenify.geox.init__stores();
  },
  init__map__geox: function() {
    const markers = [];
    const results = $('.results').find('.item');
    let activeInfoWindow;

    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 10.7726883,
        lng: 106.6960193,
      },
      zoom: 15,
    });

    if (results && results.length) {
      results.each(function() {
        const self = $(this);
        const lat = self.data('lat');
        const lng = self.data('lng');
        const name = self.find('.item__name').text();
        const address = self.find('.item__address').text();
        const phone = self.find('.item__phone').text();
        const location = new google.maps.LatLng(lat, lng);

        const marker = new google.maps.Marker({
          position: location,
          map: map,
        });

        google.maps.event.addListener(marker, 'click', function(e) {
          if (activeInfoWindow) {
            activeInfoWindow.close();
          }

          const infoWindow = new google.maps.InfoWindow({
            content:
              '<div class="map-info"><p class="map-info__name">' +
              name +
              '</p><div class="map-info__info"><p class="map-info__address">' +
              address +
              '</p><p class="map-info__phone">' +
              phone +
              '</p></div></div>',
          });

          infoWindow.open(map, marker);
          activeInfoWindow = infoWindow;
        });

        markers.push(marker);
      });
    }

    $(document).on('click', '.results .item', function() {
      const self = $(this);
      const lat = self.data('lat');
      const lng = self.data('lng');
      const latLng = new google.maps.LatLng(lat, lng);

      map.panTo(latLng);
      google.maps.event.trigger(markers[self.data('id')], 'click');
    });
  },
  init__feature_geox: function() {
    $('.geox-feature__paging .paging').slick({
      speed: 1000,
      prevArrow:
        '<button type="button" class="slick-prev"><svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.35707 18.5714L0.571359 18.5714L6.99993 9.2857L8.78564 9.2857L2.35707 18.5714Z" fill="#F6F5F5"/><path d="M6.99986 9.28569L8.78557 9.28569L2.357 -2.3998e-05L0.571288 -2.38419e-05L6.99986 9.28569Z" fill="#F6F5F5"/></svg></button>',
      nextArrow:
        '<button type="button" class="slick-next"><svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.35707 18.5714L0.571359 18.5714L6.99993 9.2857L8.78564 9.2857L2.35707 18.5714Z" fill="#F6F5F5"/><path d="M6.99986 9.28569L8.78557 9.28569L2.357 -2.3998e-05L0.571288 -2.38419e-05L6.99986 9.28569Z" fill="#F6F5F5"/></svg></button>',
    });

    $('.geox-feature__paging .paging').on('beforeChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      const number = currentSlide + 1;

      $('.geox-feature__slider').slick('slickGoTo', number);
    });

    $('.geox-feature__paging .paging').on('afterChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      const self = $(this);
      const number = currentSlide + 1;
      const eleCurrentSlide = self
        .closest('.geox-feature__paging')
        .find('[data-current-slide]');

      if (number < 10) {
        eleCurrentSlide.html('0' + number);
      } else {
        eleCurrentSlide.html(number);
      }
    });

    $('.geox-feature__slider').slick({
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      variableWidth: true,
      swipe: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          },
        },
      ],
    });

    $('.geox-feature__slider').on('beforeChange', function(
      event,
      slick,
      currentSlide,
      nextSlide,
    ) {
      $('.geox-feature__slider')
        .find('[data-slick-index="' + currentSlide + '"]')
        .removeClass('animated');

      $('.geox-feature__slider')
        .find('[data-slick-index="' + nextSlide + '"]')
        .addClass('animated');
    });
  },
  init__stores: function() {
    var ele = $('#stores__content').first(),
      url =
        ele.data('map-url') +
        '?key=' +
        ele.data('map-key') +
        '&libraries=places';

    if (
      'google' in window &&
      (typeof google === 'undefined' ? 'undefined' : _typeof(google)) ===
        'object' &&
      _typeof(google.maps) === 'object'
    ) {
      $('#stores__content').mapview();
    } else {
      $.getScript(url, function() {
        $('#stores__content').mapview();
      });
    }
  },
};
