if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.furla = {
  init__furla: function () {
    Steenify.furla.init__stores();
    Steenify.furla.init__store_view();
  },
  init__stores: function () {
    var ele = $('#stores__content').first(),
      url = ele.data('map-url') + '?key=' + ele.data('map-key') + '&libraries=places';

    if ('google' in window && (typeof google === 'undefined' ? 'undefined' : _typeof(google)) === 'object' && _typeof(google.maps) === 'object') {
      $('#stores__content').mapview();
    } else {
      $.getScript(url, function () {
        $('#stores__content').mapview();
      });
    }
  },
  init__store_view: function () {
    $('.list-store .item__name').on('click', function () {
      var selected_id = $(this).data('id');
      $('#listStore .item').each(function (idx, obj) {
        if ($(this).data('id') == selected_id) {
          $(this).trigger('click');
          $(window).scrollTop($('#listStore').offset().top);
        }
      })
    })
  }
};
