// Import css
import './scss/index.scss';

if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}

import './js/home';
import './js/about';
import './js/geox';
import './js/shooz';
import './js/furla';
import './js/careers';
import './js/gosumo';

const clickOrTouch = 'ontouchend' in window ? 'touchend' : 'click';

window.Steenify.common = {
  init: function () {
    Steenify.common.init__header();
    Steenify.common.init__page();
    Steenify.common.init__promotions_loadmore();
    Steenify.common.init__news_loadmore();
    Steenify.common.init__gosumo_product_loadmore();
    Steenify.common.init__careers_page_select_change();
    Steenify.common.init__careers_page_loadmore();
  },
  init__page: function () {
    const view = $('.steenify__page').data('view');
    switch (view) {
      case 'home':
        Steenify.home.init__home();
        break;
      case 'about':
        Steenify.about.init__about();
        break;
      case 'geox':
        Steenify.geox.init__geox();
        break;
      case 'shooz':
        Steenify.shooz.init__shooz();
        break;
      case 'furla':
        Steenify.furla.init__furla();
        break;
      case 'job-descriptions':
        Steenify.careers.init__careers();
        break;
      case 'careers':
        Steenify.careers.init__careers();
        break;
      case 'gosumo':
        Steenify.gosumo.init__gosumo();
        break;
      default:
        console.log(view);
    }
  },
  init__header: function () {
    const hamburger = $('.header__body .header__toggle');
    const menu_mobile = $('.header__body .main-menu');

    hamburger.on('click', function () {
      const self = $(this);
      self.find('.burger').toggleClass('opened');
      menu_mobile.slideToggle();
    });
  },

  init__promotions_loadmore: function () {
    const btn_loadmore = $('.btn-promotions-loadmore');
    btn_loadmore.on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      $(this).addClass('loading');

      const ids = [];
      const selector = self.closest('.container');
      selector.find('.item').each(function () {
        ids.push($(this).data('id'));
      });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'havang_promotions_loadmore',
          ids: ids,
          security: myAjax.check_nonce,
        },
        success: function (response) {
          if (response.code == 1) {
            self.removeClass('loading');
            const listing__list = selector.find('.list');
            listing__list.append(response.data);
            if (response.hide_loadmore) {
              selector.find('.loadmore-container').remove();
            }
          } else {
            self.removeClass('loading');
          }
        },
      });
    });
  },
  init__news_loadmore: function () {
    const btn_loadmore = $('.btn-news-loadmore');
    btn_loadmore.on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      $(this).addClass('loading');

      const ids = [];
      const selector = self.closest('.container');
      selector.find('.item').each(function () {
        ids.push($(this).data('id'));
      });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'havang_news_loadmore',
          ids: ids,
          security: myAjax.check_nonce,
        },
        success: function (response) {
          if (response.code == 1) {
            self.removeClass('loading');
            const listing__list = selector.find('.list');
            listing__list.append(response.data);
            if (response.hide_loadmore) {
              selector.find('.loadmore-container').remove();
            }
          } else {
            self.removeClass('loading');
          }
        },
      });
    });
  },
  init__gosumo_product_loadmore: function () {
    const btn_loadmore = $('.btn-gosumo-loadmore');
    btn_loadmore.on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      $(this).addClass('loading');

      const ids = [];
      const selector = self.closest('.gosumo-products');
      selector.find('.item-product').each(function () {
        ids.push($(this).data('id'));
      });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'havang_gosumo_product_loadmore',
          ids: ids,
          security: myAjax.check_nonce,
        },
        success: function (response) {
          if (response.code == 1) {
            self.removeClass('loading');
            const listing__list = selector.find('.gosumo-products__list');
            listing__list.append(response.data);
            if (response.hide_loadmore) {
              selector.find('.loadmore-container').remove();
            }
          } else {
            self.removeClass('loading');
          }
        },
      });
    });
  },
  init__careers_page_select_change: function () {
    $('#job_career, #select_job_location').on('change', function (e) {
      e.preventDefault();
      const self = $(this);
      // $(this).addClass('loading');
      const job_career = $('#job_career').val();
      const job_location = $('#select_job_location').val();
      const ids = [];
      const selector = self.closest('.jobs').find('.jobs__list');
      // selector.find('.item').each(function () {
      //   ids.push($(this).data('id'));
      // });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'havang_job_listing_filter',
          // ids: ids,
          job_career: job_career,
          job_location: job_location,
          security: myAjax.check_nonce,
        },
        success: function (response) {
          if (response.code == 1) {
            // self.removeClass('loading');
            selector.find('.row').html(response.data);
            if (response.hide_loadmore) {
              selector.find('.loadmore-container').addClass('hidden');
            } else {
              selector.find('.loadmore-container').removeClass('hidden');
            }
          } else {
            self.removeClass('loading');
          }
        },
      });
    });
  },
  init__careers_page_loadmore: function () {
    $('.btn-job-loadmore').on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      $(this).addClass('loading');
      const job_career = $('#job_career').val();
      const job_location = $('#job_location').val();
      const ids = [];
      const selector = self.closest('.jobs').find('.jobs__list');
      selector.find('.item').each(function () {
        ids.push($(this).data('id'));
      });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'havang_job_listing_loadmore',
          ids: ids,
          job_career: job_career,
          job_location: job_location,
          security: myAjax.check_nonce,
        },
        success: function (response) {
          if (response.code == 1) {
            self.removeClass('loading');
            selector.find('.row').append(response.data);
            if (response.hide_loadmore) {
              selector.find('.loadmore-container').addClass('hidden');
            } else {
              selector.find('.loadmore-container').removeClass('hidden');
            }
          } else {
            self.removeClass('loading');
          }
        },
      });
    });
  },
};

//***************************************
//      Main program
//***************************************
$(document).ready(function () {
  Steenify.common.init();



  // Scroll to Element
  if (window.location.hash) {
    const hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

    if (hash && $('#' + hash).length) {
      $('html, body').animate({
          scrollTop: $('#' + hash).offset().top,
        },
        'slow',
      );
    }
  }

  // Find store
  $('.submit-find-store').on('click', function () {
    const self = $(this);
    const page = $('#list_brand_select').val();

    window.location.href = '/' + page + '#stores__content';
  });

  // Init AOS
  if (AOS) {
    AOS.init({
      duration: 1200,
    });
  }

  // Scroll
  $(window).on('scroll', function () {
    const scroll = $(window).scrollTop();

    if (scroll > 100) {
      $('.header--main').addClass('scrolled');
    } else {
      $('.header--main').removeClass('scrolled');
    }
  });

  // document.addEventListener(
  //   'wpcf7submit',
  //   function (event) {
  //     if ('330' == event.detail.contactFormId) {
  //       $('#modal_apply_job_success').modal();
  //       // do something productive
  //     }
  //   },
  //   false,
  // );
  document.addEventListener(
    'wpcf7mailsent',
    function (event) {
      if ('5' != event.detail.contactFormId) {
        setTimeout(function () {
          var output = $('.wpcf7-response-output').html();

          $('#contact_form_modal_success .modal_custom__sub').html(output);
          $('#contact_form_modal_success').modal();
        }, 500)
      }
    },
    false,
  );
  document.addEventListener(
    'wpcf7invalid',
    function (event) {
      if ('5' != event.detail.contactFormId) {
        setTimeout(function () {
          var output = $('.wpcf7 .wpcf7-response-output').text();
          $('#contact_form_modal_failure .modal_custom__sub').html(output);
          $('#contact_form_modal_failure').modal();
        }, 500)
      }

    },
    false,
  );



  $('#modal_success').on('hide.bs.modal', function () {
    window.location.reload();
  });

  // functionality to copy text from inviteCode to clipboard

  $('#show_copy_link').on('click', function () {
    $('.copy-container').css('opacity', 1);
  });
  // trigger copy event on click
  $('#copy').on('click', function (event) {
    copyToClipboard(event);
  });

  // event handler
  function copyToClipboard(e) {
    // alert('this function was triggered');
    // find target element
    var t = e.target,
      c = t.dataset.copytarget,
      inp = c ? document.querySelector(c) : null;

    // check if input element exist and if it's selectable
    if (inp && inp.select) {
      // select text
      inp.select();
      try {
        // copy text
        document.execCommand('copy');
        inp.blur();

        // copied animation
        t.classList.add('copied');
        setTimeout(function () {
          t.classList.remove('copied');
        }, 1500);
      } catch (err) {
        //fallback in case exexCommand doesnt work
        alert('please press Ctrl/Cmd+C to copy');
      }
    }
  }
});
