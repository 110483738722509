if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.about = {
  init__about: function() {
    $('.about_wwd__brand').on('click', function() {
      const self = $(this);
      const index = self.index();
      const about_wwd = self.closest('.about_wwd');

      if (!self.hasClass('active')) {
        about_wwd.find('.about_wwd__caption.active').removeClass('active');
        about_wwd.find('.about_wwd__banner.active').removeClass('active');
        about_wwd.find('.about_wwd__brand.active').removeClass('active');

        about_wwd
          .find('.about_wwd__caption')
          .eq(index)
          .addClass('active');

        about_wwd
          .find('.about_wwd__banner')
          .eq(index)
          .addClass('active');

        self.addClass('active');
      }
    });
  },
};
