if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.careers = {
  init__careers: function() {
    const job_title = $('#job_title').val();
    $('#apply_job').html('');
    $('#apply_job').append(`
      <option value="${job_title}">${job_title}</option>  
    `);

    $('#job_location').html('');
    $('.job_location').each(function(key, item) {
      if (item.value != undefined) {
        $('#job_location').append(`
      <option value="${item.value}">${item.value}</option>  
    `);
      }
    });
    $('#job_url').val($('#post_url').val());
    this.init_upload_file();
    this.init_hover_why();
  },

  init_upload_file: function() {
    var form_upload = $('.job-descriptions__form');
    var form_trigger = $('.job-descriptions__trigger');
    var form_file = $('.job-descriptions__file');

    var input = form_upload.find('#file_cv');
    input.on('change', function(e) {
      form_trigger.addClass('d-none');
      form_upload.removeClass('d-none').addClass('d-inline-block');

      form_file.html('File: ' + e.target.files[0].name);
    });

    document.addEventListener(
      'wpcf7submit',
      function(event) {
        console.log(
          'TCL: event.detail.contactFormId',
          event.detail.contactFormId,
        );

        if ('5' == event.detail.contactFormId) {
          $('#modal_success').modal('show');
        }
      },
      false,
    );
  },

  init_hover_why: function() {
    $('.careers-why .item').mouseenter(function() {
      const self = $(this);
      const src = self.data('src');

      if (src) {
        self
          .closest('.careers-why ')
          .find('.careers-why__image-part')
          .removeClass('active');
        self
          .closest('.careers-why ')
          .find('.careers-why__image-part')
          .each(function() {
            var item = $(this);

            if (item.attr('src') === src) {
              item.addClass('active');
            }
          });
      }
    });
  },
};
